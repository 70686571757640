.fade-enter {
    opacity: 0;
    transform: translateY(100px); /* Initial transition effect */
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.fade-exit {
    opacity: 0.75;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}
